// @flow

import React, { useState, useEffect, useContext } from 'react';
import { marked } from 'marked';
import ReactHTMLParser from 'react-html-parser';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import Helmet from 'react-helmet';
import { Hero } from '@latitude/hero';
import styled from 'styled-components';
import { Accordion } from '@latitude/accordion';
import { FeaturesSlider } from '@latitude/features-slider';
import { Box, Flex } from '@latitude/box';
import { Heading3 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { Link } from '@latitude/link';
import { Strong, Text } from '@latitude/text';
import SvgInline from '@latitude/svg-inline';
import Layout from '../components/layout';
import PageData from '../data/pages/loans.json';
import footerData from '../data/json/footer.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import LoanCardBranded from '../components/LoanCard/LoanCardBranded';
import { CardGroup, CardGroupItem } from '../components/CardGroup/CardGroup';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  FONT_FAMILY,
  FONT_WEIGHT
} from '../utils/constants';
import { SOFT_QUOTE_LOANS_URL } from '../utils/softQuoteUtil';
import HeroBranded, { HeroImage } from '../components/HeroBranded/HeroBranded';
import CalculatorLinks from '../components/CalculatorLinks/CalculatorLinks';
import ManageYourLoan from '../components/ManageYourLoan/ManageYourLoan';
import Modal from '../components/Modal/Modal';
import { FeaturesAndBenefitsSlider } from '@latitude/features-and-benefits-slider';
import { PageContext } from '../context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { CONTENTFUL_COMPONENTS } from '../utils/constants';
import { useSetState } from '@/utils/hooks';

const AllLoansPage = () => {
  // const [wantToDo, setWantToDo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const featureSliderItems = [
    {
      icon: 'icon-snap-fingers-branded',
      title: 'Un-complicated loans',
      text:
        'Get an indicative Quote in 2 minutes, Apply in under 7 minutes. Once approved and you accept your contract, funds in your account in less than 24 hours.'
    },
    {
      icon: 'icon-slinky',
      title: 'Flexible options',
      text:
        'At Gem, you can choose to apply for a Fixed or Variable loan, along with an option to set up monthly, fortnightly or weekly repayments.'
    },
    {
      icon: 'icon-extras-branded',
      title: 'Redraw extra funds',
      text:
        "Get flexible access to funds when you need them, when you're ahead on repayments with a Variable Rate Personal Loan."
    }
  ];
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    featureSliderData: [
      {
        heading: 'Why choose a Gem loan?',
        featureCards: featureSliderItems
      }
    ]
  });

  useEffect(() => {
    let [featureSliders, heroBanners] = [[], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ''),
            description: ReactHTMLParser(marked(item.description || '')),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || '')
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData:
        heroBanners.length > 0 ? heroBanners : state.heroBannerData,
      featureSliderData:
        featureSliders.length > 0 ? featureSliders : state.featureSliderData
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <Layout noMetaKeywords customFooter={footerData}>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer all-loans-loan-page">
        <Helmet>
          <link rel="canonical" href="https://www.gemfinance.co.nz/loans/" />
          <title>All Loans NZ | Loans Category | Gem by Latitude</title>
          <meta
            name="description"
            content="Gem personal loan rates are fixed for the life of the loan, so you'll always know what your repayments will be. T&amp;Cs &amp; fees apply."
          />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Hero
            css={`
              && {
                background: #0146aa;
                background: linear-gradient(135deg, #0146aa 0%, #0162ed 100%);
                color: #fff;

                h1,
                div,
                p {
                  color: #fff;
                }

                .HeroContent {
                  [class^='Linkstyled__StyledLink-'] {
                    color: #0061ee !important;
                    background-color: #fff !important;
                    border-color: transparent !important;

                    &:hover {
                      box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 13px 0px;
                      transition: all 150ms ease-in-out 0s;
                      border-bottom: 1px solid rgb(0, 106, 255);
                      text-shadow: rgb(0, 106, 255) 0px 0px 1px;
                      background-color: #cee6ff !important;
                      border-color: #cee6ff;
                    }
                  }
                }

                @media (max-width: ${BREAKPOINT.MD}) {
                  h1,
                  div,
                  p {
                    color: #fff !important;
                  }

                  .HeroContent {
                    padding: 10px;
                    background-color: transparent;
                  }
                }
              }
            `}
            imageContent={
              <div
                id="hero"
                css={`
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    justify-content: flex-end;
                  }
                `}
              >
                <img
                  src={require('../images/pl-latitude-loans_hero-image-au_417x395.webp')}
                  css={`
                    height: 280px;
                    width: auto;
                    margin: 0;
                    @media (min-width: ${BREAKPOINT.MD}) {
                      height: 400px;
                      margin: 70px -60px 0 0;
                    }
                    @media (min-width: ${BREAKPOINT.LG}) {
                      height: 555px;
                      margin: 0 -90px 0 0;
                    }
                  `}
                  alt="Low Rate Card"
                />
              </div>
            }
            title={PageData.content.heroTitle}
            text={[
              'With a Gem personal loan you can make things happen. Plus, you can pay your loan off faster to save on interest, with no early repayment fees. Check your rate in just 2 minutes online – why not do it right now?'
            ]}
            footnote={[
              <sup>*</sup>,
              'Rates tailored to you from 8.99% for secured variable loans and 9.99% for secured fixed loans. Credit and lending criteria, and fees apply, including a $240 establishment fee.'
            ]}
          />
        )}

        <AnalyticsLocationProvider location="Our Gem loans">
          <Box.Section backgroundColor={COLOR.GREY6}>
            <Heading3
              css={`
                font-size: 24px;
                color: ${COLOR.BLACK};
                text-align: center;
                @media (min-width: ${BREAKPOINT.LG}) {
                  font-size: 32px;
                }
              `}
            >
              Our Gem loans
            </Heading3>
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-sm-8 col-md-7 col-lg-12">
                <CardGroup>
                  <CardGroupItem col={2}>
                    <LoanCardBranded
                      icon="icon-personal-loan"
                      isBranded
                      className="h-100"
                      title="Personal Loan"
                      description={
                        <>
                          Renovating? Consolidating debts? Getting married or
                          taking a trip? Whatever you need, let&apos;s do it
                          with a Gem Personal Loan.
                        </>
                      }
                      rateBoxType="personalSym"
                      rateBoxType2="personalUnsecuredSym"
                      rateBoxFullWidth
                      rateBoxSize="large"
                      listItems1={[
                        'Loan amounts from $2,000',
                        'Loan terms from 1 to 7 years',
                        'No early repayment fees',
                        'Establishment fee of $240 applies',
                        'Your interest rate depends on your personal circumstances'
                      ]}
                      button1={{
                        href: '/loans/personal-loans/',
                        trackId: 'personal-loan-tell-me-more',
                        text: 'Tell me more',
                        trackEventData: {
                          location: 'Personal Loan'
                        }
                      }}
                      button2={{
                        marginLeft: '12px',
                        href: SOFT_QUOTE_LOANS_URL,
                        trackId: 'personal-loan-get-my-rate',
                        text: 'Get rate estimate',
                        target: '_self',
                        trackEventData: {
                          category: 'cta',
                          action: 'quote-link',
                          location: 'Personal Loan'
                        }
                      }}
                    />
                    <div className="d-lg-none">
                      <SecuredVersusUnsecuredLink setShowModal={setShowModal} />
                    </div>
                  </CardGroupItem>
                </CardGroup>
              </div>
            </div>

            <div className="d-none d-lg-block">
              <SecuredVersusUnsecuredLink setShowModal={setShowModal} />
            </div>
            {/*
             * TODO
             * 1. Move below copy to global json file when merged into release branch
             */}
            <Modal
              isOpen={showModal}
              onRequestClose={() => setShowModal(false)}
              title="Secured vs Unsecured loan?"
              content="When shopping around or applying for a personal loan, one decision you'll need to make is whether you want a secured or unsecured loan. Taking out a secured loan means you're able to provide an asset like your car, for Gem to secure your loan against. An advantage of taking out a secured loan is that you may be eligible for a lower interest rate. If you don't have a suitable asset to secure your loan against, you may decide to apply for an unsecured loan."
              ariaHideApp={false}
            />
          </Box.Section>
        </AnalyticsLocationProvider>

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            backgroundColor={COLOR.GREY6}
            heading={state.featureSliderData[0].heading}
            subheading={state.featureSliderData[0].description}
            data={state.featureSliderData[0].featureCards}
            className="why-choose pt-4 pt-md-5"
          />
        )}

        <CalculatorLinks />
        <div
          css={`
            && {
              background-color: #f8f8f8;
              padding-top: 10px;
              padding-bottom: 0;

              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 42px;
                padding-bottom: 0;
              }
            }
          `}
        >
          <ManageYourLoan
            heading="Managing my loan"
            description="We're here to help. Learn more about managing your loan."
          >
            <Accordion
              titleBgColor="grey-light"
              items={[
                {
                  id: 'item-1',
                  title: 'How much can I borrow?',
                  content: (
                    <div className="w-100">
                      <div className="row">
                        <div className="col-12 mt-1">
                          <Strong>Gem Personal Loan</Strong>
                          <Text>
                            The minimum amount you can borrow for a Gem Personal
                            Loan is $2,000, with the maximum depending on your
                            circumstances and current financial situation.
                          </Text>
                        </div>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'item-2',
                  title: 'How long until I receive my funds?',
                  content: (
                    <Text>
                      Once your application has been approved and if you read
                      and accept the contract online before 6:30pm on a business
                      day, we will make the payment that same day. You should
                      see the money in your account the next business day
                      (depending on your terms with your bank).
                    </Text>
                  )
                },
                {
                  id: 'item-3',
                  title:
                    'What is the difference between a Fixed and Variable Rate Loan?',
                  content: (
                    <Text>
                      Fixed Rate loans mean the interest rate and your
                      repayments will not change. Variable Rate loans mean that
                      the interest rate and your repayments may change
                      throughout your loan.
                    </Text>
                  )
                }
              ]}
            />
          </ManageYourLoan>
        </div>
        <FeaturesResponsiveSlider
          heading={PageData.content.loanUseTitle}
          css="background-color: #f8f8f8;"
          data={PageData.categories}
          withBtn
          className="whats-important pb-md-5"
          responsiveSettings={[
            {
              breakpoint: 10000,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 0,
                infinite: false
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 460,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            }
          ]}
        />

        <ImportantInformation
          data={require('../data/pages/loans.json')}
          sectionOneColummClasses="col-10 offset-1"
        />
      </main>
    </Layout>
  );
};

export default AllLoansPage;

// Styles

const StyledHeroBranded = styled(HeroBranded)`
  background-color: ${COLOR.GREY6};

  ${HeroImage} {
    height: 260px;

    @media (min-width: ${BREAKPOINT.MD}) {
      height: auto;
    }
  }
`;

const FeaturesResponsiveSlider = styled(FeaturesAndBenefitsSlider)`
  && {
    padding-top: 48px;
    padding-bottom: 48px;

    @media (min-width: ${BREAKPOINT.LG}) {
      padding-top: 72px;
      padding-bottom: 72px !important;
    }

    @media (max-width: ${BREAKPOINT.LG}) {
      .container.section__content {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }

    .loan-used-for-categories__title,
    .card-icon__title {
      font-family: ${FONT_FAMILY.TITLE};
      color: ${COLOR.BLACK};
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      margin-top: 0 !important;
    }

    .loan-used-for-categories__title {
      font-size: 24px;
      line-height: 32px;
      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 32px;
      }
    }

    .lfs-slide {
      background-color: #fff;
      padding-top: 0 !important;
      padding-bottom: 38px !important;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    }

    .card-icon {
      padding-top: 0;
      padding-bottom: 0;
    }

    .card-icon__image {
      position: relative;
      z-index: 0;
      margin-left: -16px;
      width: calc(100% + 32px);
      max-height: 192px;
      overflow: hidden;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 35px;
        transform: skew(0, -5deg);
        background: #fff;
        transform-origin: top left;
        position: absolute;
        bottom: -26px;
      }
      img {
        border-radius: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    .card-icon__title {
      @media (max-width: ${BREAKPOINT.LG}) {
        line-height: 24px;
      }
    }

    .card-icon__title,
    .card-icon__content {
      padding: 0;
    }

    .card-icon__title {
      font-size: 18px;
      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 20px;
      }
    }
  }
`;

export const SecuredVersusUnsecuredLink = ({ setShowModal }) => {
  return (
    <Flex
      marginTop={MARGIN.M16}
      alignItems={ALIGN.CENTER}
      css={`
        justify-content: ${ALIGN.LEFT};
        margin-left: auto;
        margin-right: auto;
        max-width: 350px;

        @media (min-width: ${BREAKPOINT.MD}) {
          justify-content: ${ALIGN.CENTER};
        }
      `}
    >
      <SvgInline
        name="info-block"
        style={{ width: 16, fill: COLOR.BLUE, marginRight: 4 }}
      />
      <Link
        onClick={(event: SyntheticEvent<HTMLButtonElement>) => {
          event.preventDefault();
          setShowModal(true);
        }}
        href="#"
        role="presentation"
        data-trackid="rates-secured-vs-unsecured-link"
        css={`
          font-size: 14px;
          color: #000 !important;
          border-bottom: 0 !important;
          text-decoration: underline !important;
        `}
      >
        Secured vs Unsecured loan?
      </Link>
    </Flex>
  );
};
